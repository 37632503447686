
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import App from '../App.vue';  
  import $ from 'jquery';  
  import 'bootstrap';
  import Basic from '@/components/Basic.vue';  
  import Toast from '@/components/Toast.vue'
  import { useRoute } from 'vue-router';
  import { useToast } from "vue-toastification";
  import moment from 'moment';
 
  import Axios from "axios";
import Currency from '../Currency.vue';
  @Options({
    components: {  
      Basic,
      'toast':Toast
    }, 
  })

  export default class CashAccountAgreement extends Vue {
    errors : string[]   =   [];
    err : any[]         =   [];
    doc_file :any;
    token :any          =   ''; 
    approval :any       =  ''; 
    confirm :any        =   0; 
    link_code :any      =   ''; 
    doc_name :any       =   'Accountant document'; 
    doc_file_error:any  =   "";
    form                =   {};
    countries           =   [];
    error_flag          =   0;
    no_of_cash_ac       =   1;
    confirm_error       =   '';
    states              =   [];
    cities              =   []; 

    is_corporate    :any    =    0;
    corp_directors  :any    =    [];
    corp_token      :any    =   '';
    is_director     :any    =    0;
    dir_error       :any    =    0;

    uToken :any         =   '';  
      
    account_currency:any      =   [];

    name_of_persons_1:any     =   []; 
    email_of_persons_1:any    =   [];
    phone_of_persons_1:any    =   [];
    authority_of_persons_1:any=   [];
    
    name_of_persons_2:any     =   [];
    email_of_persons_2:any    =   [];
    phone_of_persons_2:any    =   [];
    authority_of_persons_2:any=   [];

    approval_doc_list:any=   [];

    director_input:any  =   [];

    current_date        =   moment(new Date()).format("DD/MM/YYYY");
    url_paras           =   {};
    STORAGE_KEY         =   "caprock-local";  
    session             =   JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    local_storage:any;
    toast:any = useToast();

        
    
    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';    

      const route = useRoute() 
      this.url_paras            =   route.query; 
      this.token                =   this.url_paras['token']; 
      this.approval             =   this.url_paras['approval']; 
      this.link_code            =   this.url_paras['ltoken'];  
      

      this.account_currency[0]  =   '';
      this.corp_token           =   this.token;
      this.uToken               =   this.token;
      
      this.checkTokenUserType();
      return {  
        is_corporate        :   this.is_corporate,
        is_director         :   this.is_director,
        corp_directors      :   this.corp_directors,
        corp_token          :   this.corp_token,
        dir_error           :   this.dir_error,
        approval_doc_list      :   this.approval_doc_list,
        
        form: { 
          e_name        : '',
          e_phone_no    : '',
          e_email       : '',
          e_dob         : '',
          curdate       : '', 
          cash_accounts : {
            'account_currency'      : this.account_currency,
            
            'name_of_persons_1'     : this.name_of_persons_1,
            'email_of_persons_1'    : this.email_of_persons_1,
            'phone_of_persons_1'    : this.phone_of_persons_1,
            'authority_of_persons_1': this.authority_of_persons_1,

            'name_of_persons_2'     : this.name_of_persons_2,
            'email_of_persons_2'    : this.email_of_persons_2,
            'phone_of_persons_2'    : this.phone_of_persons_2,
            'authority_of_persons_2': this.authority_of_persons_2,

            'director_input'        : this.director_input  
          },
          no_of_cash_ac : this.no_of_cash_ac 
        } 

      } 
    }   

    async checkTokenUserType(){
      var uType       =   await this.getUserType();
      var tokenType   :any =   null;
      let form_data   = new FormData();  
      form_data.append('user_token',this.local_storage['access-token']); 
      if(typeof this.uToken !='undefined' && this.uToken!=null){
          form_data.append('token',this.uToken);  
          Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
          var url =Common.base_url()+'get-user-token-info';    
          await Axios.post(
            url, 
            form_data, 
            {
                headers: {  
                    'Content-Type': 'multipart/form-data'
                } 
            }
          )
          .then(response => { 
            if(response.data.success==200){  
              tokenType = response.data.userType; 
              if(uType!=tokenType){ 
                if(tokenType!='my'){ 
                    var cNo =tokenType.replace('business_','');     
                    this.switch_profile('business',cNo);
                }else{
                    this.switch_profile('my',0);
                } 
              } 
            }else{ 
                this.toast.info(response.data.message);  
            } 
          })
          .catch(error => { 
              this.toast.error('Failed to upload');   
              return false;
          });  

      }else{ 
          this.toast.info('Please select file first');   
      } 
    } 
    async getUserType(){
      var uType = localStorage.getItem('active_profile');
      return uType;
    } 
    async switch_profile(p_type:any,company_no=0){ 
      if(p_type=='business'){
        /*SWITCH TO MY BUSINESS AC IN DIRECTOR*/ 
        await Common.switch_b_account(this.token,2,company_no)
        .then(response => { 
            if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                localStorage.setItem('active_profile','business_'+company_no);  
                location.reload();
            }
        }).catch(e => {
              console.log(e)
        });
      } if(p_type=='my'){
        /*SWITCH TO INDIVIDUAL*/
        await Common.switch_b_account(this.token,1,company_no)
        .then(response => { 
            if (typeof response.data !== 'undefined' && response.data.success == 200) {  
            localStorage.setItem('active_profile','my');  
            location.reload();
            }
        }).catch(e => {
            console.log(e)
        });
      } 
    }



    
    updateACurrency(){  
      var new_account_currency: any = [];  
      for (let i = 0; i < this.no_of_cash_ac; i++) { 
        new_account_currency[i]=this.account_currency[i];
        if(typeof this.account_currency[i]==='undefined' || this.account_currency[i]==null) { 
          new_account_currency[i]='';
        }
      }
      this.account_currency = new_account_currency; 

    }
    created() {     
      var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    

      /*var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
          if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){  
              this.token=js['access-token'];
          }else{  
              localStorage.removeItem(this.STORAGE_KEY); 
              this.$router.push('/sign-in'); 
          } 
          document.title = "Account Credit Form  || Caprock Merchant Platform";
          localStorage.setItem(
              'active_page', 'account_form'
          );*/


      this.token_alert(); 
      if(this.approval && this.approval!=''){
        this.is_approval_valid(this.approval); 
      }else{
        this.is_approval_valid(0); 
      } 
      this.form['curdate'] = moment(new Date()).format("YYYY-MM-DD")
      document.title = this.doc_name+' || Caprock Merchant Platform';    

      this.get_countries();


      /*REMOVE FORM PRODUC*/
      // this.director_input=["18_0","17_0"];
      // this.is_director =0;
      /*REMOVE FORM PRODUC*/


      return true;
    }
 

    get_countries() { 
      Common.get_countries_list()
        .then(response => { 
            if (typeof response.data !== 'undefined' && response.data.success == 200) {
                this.countries = response.data.countries;
            }
        }).catch(e => {
            console.log(e)
        }); 
    }
    fetch_file(event:any){  
        var file = event.target.files;  
        if(event.target.name=='doc_file') {
          this.doc_file         =   file; 
          this.form['doc_file_name']    =   file.name; 
        }  
    }
    token_alert(){
      if(typeof this.token === 'undefined' || this.token == '' || this.token == 'undefined'){  
        alert('This URL is invalid please contact caprock support team');
        location.href='https://caprock.com.au'
      }
    }

    set_directors(user_id:any){
      this.is_corporate = 1; 
      Common.get_directors_token(this.corp_token)
      .then(response => {
        if (typeof response.data !== 'undefined' && response.data.success != 200) {     
          (this.$refs['toast'] as any).miralToast( 
              'Warning',
              'Invalid Token!',
              '',
              false /*Reload TRUE FALSE*/
          ); 
          setTimeout(function(){
            location.href="https://caprock.com.au";
          },2500); 
        }else{ 
          this.corp_directors = response.data.directors;
        }  
      }).catch(e => {
        console.log(e)
      });

    }
     
    is_approval_valid(approval_id:any) { 
        Common.is_approval_valid(approval_id,this.token)
          .then(response => {
              console.log(response);
              if (typeof response.data !== 'undefined' && response.data.success != 200) {    
                // alert('Link expired!');
                (this.$refs['toast'] as any).miralToast( 
                    'Warning',
                    'Link expired!',
                    '',
                    false /*Reload TRUE FALSE*/
                ); 
                setTimeout(function(){
                  location.href="https://caprock.com.au";
                },2500); 
              }else{
                if(response.data.data.user_type==2 || response.data.data.user_type=='2'){
                  this.set_directors(response.data.data.user_id);
                }

                if(response.data.data.approval_doc_list.length>0){
                  this.approval_doc_list = response.data.data.approval_doc_list;
                }
 

                if(typeof response.data.data.approval_directors!='undefined' && response.data.data.approval_directors!=''){
                  
                  if(typeof response.data.master!='undefined' && typeof response.data.master.approval_response!='undefined' && response.data.master.approval_response!=null)
                  {
                    var ap_res = JSON.parse(response.data.master.approval_response);
                    if (typeof ap_res!='undefined'){
                      this.no_of_cash_ac =  ap_res['no_of_cash_ac']; 
                      this.account_currency=ap_res['cash_accounts']['account_currency']; 
                    }
                     
                  }

                  this.corp_token       =   response.data.data.master_token; 
                  this.director_input   =   JSON.parse(response.data.data.approval_directors); 
                   
                  this.set_directors(response.data.data.director_master_id);
                  this.is_director = 1;
                }



              }  
          }).catch(e => {
            console.log(e)
        });
    }

    submitForm1(e:any){     
      var js = JSON.parse(JSON.stringify((this.form)));   
      // console.log(js); 
    }


    checked_director(){
      alert('S');      
    }

     
    submitForm(e:any){  
      /*this.checked_director();*/

      $('#subBtn').attr('disabled', 'disabled');
      $('#subBtn').text('Processing ... ');
      this.form['cash_accounts'] = {
        'is_corporate'          : this.is_corporate,
        
        'account_currency'      : this.account_currency,
        
        'name_of_persons_1'     : this.name_of_persons_1,
        'email_of_persons_1'    : this.email_of_persons_1,
        'phone_of_persons_1'    : this.phone_of_persons_1,
        'authority_of_persons_1': this.authority_of_persons_1,

        'name_of_persons_2'     : this.name_of_persons_2,
        'email_of_persons_2'    : this.email_of_persons_2,
        'phone_of_persons_2'    : this.phone_of_persons_2,
        'authority_of_persons_2': this.authority_of_persons_2,

        'director_input'        : this.director_input

      };
      this.form['no_of_cash_ac'] = this.no_of_cash_ac ;
      var js = JSON.parse(JSON.stringify((this.form)));  
       


      /*Validation Check*/
        this.errors       = [];
        this.error_flag   = 0;
        this.dir_error   = 0;
        var required  = [
          { 'key': 'e_name', 'name': 'Your Name' }, 
          { 'key': 'e_email', 'name': 'Email' }, 
          { 'key': 'e_phone_no', 'name': 'Phone No' },
          { 'key': 'e_dob', 'name': 'Date Of Birth' } 
        ];
        var valid_emails = [ 
          { 'key': 'e_email', 'name': 'E-Signature Email' }
        ];

        var valid_phones = [ 
          { 'key': 'e_phone_no', 'name': 'E-Signature Phone' }
        ]; 
        
        if(this.no_of_cash_ac<1){
          this.error_flag = 1;
          (this.$refs['toast'] as any).miralToast( 
              'Warning',
              'Please enter valid number of cash accounts',
              '',
              false
          ); 
        }else{
            
          if(this.is_corporate==0){ 
            for (let i = 1; i <= this.no_of_cash_ac; i++) {
              /*Required Fields*/
              var item1 = { 'key': 'name_of_persons_1_'+i, 'name': 'Full name and official position' };
              required.push(item1);

              var item2 = { 'key': 'phone_of_persons_1_'+i, 'name': 'Phone Number' };
              required.push(item2);

              var item3 = { 'key': 'email_of_persons_1_'+i, 'name': 'Email Address' };
              required.push(item3);

              var item4 = { 'key': 'authority_of_persons_1_'+i, 'name': 'Authority' };
              required.push(item4);

              var item5 = { 'key': 'account_currency_'+i, 'name': 'Select Currency' };
              required.push(item5);
              
              /*Phone No*/
              var item_p2 = { 'key': 'phone_of_persons_1_'+i, 'name': 'Phone Number' };
              valid_phones.push(item_p2); 
              
              /*Emails*/
              var item_e3 = { 'key': 'email_of_persons_1_'+i, 'name': 'Email Address' };
              valid_emails.push(item_e3); 
            }  
          }
        } 

        

        var new_errors: any = []; 
        for (let i = 0; i < valid_emails.length; i++) {
            this.validEmail(valid_emails[i]['key'], valid_emails[i]);
        }
        for (let i = 0; i < required.length; i++) {
            this.requiredInput(required[i]['key'], required[i]);
        }



         
      let form_data = new FormData();  
      if(this.confirm==0){
        this.confirm_error  = "Please agree to condition.";
        this.error_flag     = 1;
      }else{
        this.confirm_error="";
      } 
      
      var c_acs       =   js['cash_accounts'];
      var currencies  =   c_acs['account_currency'];
     
         
      // Currency selected or not
      if(currencies.length==0 || currencies.length<this.no_of_cash_ac){
        this.error_flag   = 1;
        this.dir_error = 1;
        this.doc_file_error   =   'Please select valid currency for All Cash Accounts.'; 
        (this.$refs['toast'] as any).miralToast( 
            'Warning',
            this.doc_file_error,
            '',
            false
        ); 
      }else{
        for (let i = 0; i < currencies.length; i++) { 
          if(typeof currencies[i]=='undefined' || currencies[i]=='' || currencies[i]==null){
            this.error_flag =1;
            this.dir_error = 1;
            this.doc_file_error   =   'Please select valid currency account no - '+String(i+1); 
            (this.$refs['toast'] as any).miralToast( 
                'Warning',
                this.doc_file_error,
                '',
                false
            ); 
          }          
        }
      }
      
      if(typeof this.is_corporate!=='undefined' && this.is_corporate==1 && this.corp_directors.length>0){
        var d_list      =   c_acs['director_input']; 
         
        // Check for cash account checkbox in director list for each account
        var dirExist = 0;
        for (let i = 0; i < this.no_of_cash_ac; i++) {
          dirExist=0; 
          for (let ds = 0; ds < this.corp_directors.length; ds++) { 
            if(d_list.includes(this.corp_directors[ds]['director_id']+'_'+i)){
              dirExist=1;
            } 
          }
          if(dirExist==0){
            this.dir_error = 1;
            this.error_flag =1;
            this.doc_file_error   =   'Please check at list one director for Cash Account No - '+String(i+1); 
            (this.$refs['toast'] as any).miralToast( 
                'Warning',
                this.doc_file_error,
                '',
                false
            ); 
          }
 
          if(typeof currencies[i]=='undefined' || currencies[i]=='' || currencies[i]==null){
            this.error_flag =1;
            this.dir_error = 1;
            this.doc_file_error   =   'Please select valid currency account no - '+String(i+1); 
            (this.$refs['toast'] as any).miralToast( 
                'Warning',
                this.doc_file_error,
                '',
                false
            ); 
          }          
        } 
      }
 





      if(this.error_flag == 0){  
        for (var key in js) {
          if(key=='cash_accounts'){ 
            form_data.append(key, JSON.stringify(js[key])); 
          }else{
            form_data.append(key, js[key]); 
          }
        }
        $('#subBtn').attr('disabled','disabled');
        $('#subBtn').text('Processing ... '); 
        form_data.append('token', this.token);  
        form_data.append('approval', this.approval);       
 

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url =Common.base_url()+'customer-approval-agreement';  
        Axios.post(url, 
          form_data, 
          {
            headers: {  
              'Content-Type': 'multipart/form-data'
            }, 
            onUploadProgress: function( progressEvent:any ) {
              var loaded:number  =  progressEvent.loaded;
              var total:number   =  progressEvent.total;
              var per   =    parseInt((loaded/total *100).toFixed()) ;  
            }.bind(this)
          }
        )
        .then(response => { 
          if(response.data.success==200){    
            (this.$refs['toast'] as any).miralToast( 
                'Sucess',
                response.data.message,
                '', 
                false /*Reload TRUE FALSE*/
            ); 
            setTimeout(() => { 
              this.$router.push('/sign-in'); 
            }, 2500) 

            $('#subBtn').removeAttr('disabled');
            $('#subBtn').text('Submit Now'); 
          }else{ 
            (this.$refs['toast'] as any).miralToast( 
                'Warning',
                response.data.message,
                '',
                false /*Reload TRUE FALSE*/
            ); 
            // location.reload();
            $('#subBtn').removeAttr('disabled');
            $('#subBtn').text('Submit Now');
          } 
        })
        .catch(error => {
          $('#subBtn').removeAttr('disabled');
          $('#subBtn').text('Submit Now'); 
        });
      }else{
        if (this.dir_error!=1) {
          this.doc_file_error='Please fill E-Signature & other details required in form before submiting.'; 
        }
        (this.$refs['toast'] as any).miralToast( 
            'Warning',
            this.doc_file_error,
            '',
            false
        );
        $('#subBtn').removeAttr('disabled');
        $('#subBtn').text('Submit Now');
        return false; 
      } 
             
    }  

    checkPhone(event: any) {
        var tag_name = event.target.name;

        var ph = event.target.value
        var valid = 1;
        var error_msg = 'Please enter valid phone number';


        if (ph.length < 7 || ph.length > 12) {
            valid = 0;
            error_msg = 'Phone number length is not valid';
        }
        if (isNaN(ph)) {
            valid = 0;
            error_msg = 'Only number character is allowed';
        }
        if (ph == '') {
            valid = 0;
            error_msg = 'Please enter phone number';
        }
 
        $('.' + tag_name + 'err').html('');
        if (valid ==0) {
            $("#" + tag_name).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + error_msg + '</span>');
        }
    } 
    validEmail(e_id, obj: any) {
        var tag_name = $("#" + e_id).attr("name");
        var e_val = $("#" + e_id).val();
        var valid = 1
        var error_msg = 'Please enter valid email address';
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (typeof obj !== "undefined" && obj !== null) {
            error_msg = 'Please enter a valid email address for field - ' + obj.name;
        }
        if (!re.test(String(e_val).toLowerCase())) {
            valid = 0;
        }
        $('.' + tag_name + 'err').html('');
        if (valid == 0) {
            this.error_flag=1;
            $("#" + e_id).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + error_msg + '</span>');
        }
    }

    requiredInput(r_id, obj: any) {
        var tag_name = $("#" + r_id).attr("name");
        var r_val = $("#" + r_id).val();

        var valid = 1;
        var em = 'Field required';

        if (typeof r_val == undefined || r_val == '' || r_val == null) {
            em = tag_name + ' is required';
            valid = 0;
        }
        if (typeof(obj) !== "undefined" && obj != null) {
          em = obj.name+ ' is required';
        }

        if (valid == 0) {
            this.error_flag=1;
            $('.' + tag_name + 'err').html('');
            $("#" + r_id).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + em + '</span>');
        }
    }
    requiredRadio(r_id, obj: any) {
        var tag_name = $('input[name="'+r_id+'"]').attr("name");
        var r_val = this.form[r_id];
         
        var valid = 1;
        var em = 'Field required';

        if (typeof r_val == undefined || r_val == '' || r_val == null) {
            em = 'Field required';
            valid = 0;
        } 
        if (valid == 0) {
            this.error_flag=1;
            $('.' + tag_name + 'err').html('');
            $('#'+r_id+'_label').append('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + em + '</span>');
        }
    }

    remove_err(event:any){ 
      $('.'+event.currentTarget.id+'err').remove();
    }
    removeTagErr(event: any) {
      var tag_name = event.target.name;
      $('.' + tag_name + 'err').html('');
    }
 
    openModal(id:any){
      $('#'+id).addClass('d-block');
      $('#'+id).addClass('show');
      $('#'+id).css('overflow-y','scroll');
    }
    closeModal(id:any){
      $('#'+id).removeClass('d-block');
      $('#'+id).addClass('d-none');
      $('#'+id).removeClass('show'); 
    }
 
      
  } 
