import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4682b64"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "toastJam",
  tabindex: "-1",
  "aria-labelledby": "toastLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = {
  class: "modal-content",
  style: {"background":"black"}
}
const _hoisted_4 = {
  class: "modal-header",
  style: {"padding":"5px 10px","color":"white"}
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "ploader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h5", {
              innerHTML: _ctx.toast_title,
              class: "modal-title",
              id: "toastLabell",
              style: {"font-size":"15px","font-weight":"600","letter-spacing":"2px","color":"white"}
            }, null, 8, _hoisted_5),
            _createElementVNode("span", {
              class: "p-cursor miral_close_icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal('toastJam',_ctx.reload)))
            })
          ]),
          _createElementVNode("div", {
            innerHTML: _ctx.toast_message,
            id: "toastBodyy",
            class: "modal-body",
            style: {"padding":"5px 15px","font-size":"14px","font-weight":"500","letter-spacing":"1px","color":"white"}
          }, null, 8, _hoisted_6)
        ])
      ])
    ]),
    (_ctx.load_on==1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
      : _createCommentVNode("", true)
  ], 64))
}