
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import App from './App.vue';  
  import $ from 'jquery';  
  import 'bootstrap';
  import Basic from '@/components/Basic.vue';  
  import Toast from '@/components/Toast.vue'
  import { useRoute } from 'vue-router'
 
  import Axios from "axios";
  @Options({
    components: {  
      Basic,
      'toast':Toast
    }, 
  })

  export default class UploadDoc extends Vue {
    errors : string[]=[];
    err : any[]=[];
    doc_file :any ;
    token :any        =   ''; 
    doc_token :any    =   ''; 
    link_code :any    =   ''; 
    doc_name :any     =   'Accountant document'; 
    doc_file_error:any=   "";
    form      ={};
    countries =[];
    states    =[];
    cities    = []; 
    url_paras = {};
    STORAGE_KEY = "caprock-local";  
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';    
      const route = useRoute() 
      this.url_paras  = route.query; 
      this.token      = this.url_paras['token']; 
      this.doc_token  = this.url_paras['doctoken']; 
      this.link_code  = this.url_paras['ltoken']; 
     
      return {  
        form: {
          doc_file_name   : '' 
        }  
      }
    }   
 
    created() {     
      var js =JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));     
      this.token_alert(); 
      if(this.doc_token && this.doc_token!=''){
        this.get_doc_details(this.doc_token);
        this.get_link_details(this.link_code);
      }
      document.title = this.doc_name+' || Caprock Merchant Platform';  
      return true;
    }

    fetch_file(event:any){  
        var file = event.target.files[0];  
        if(event.target.name=='doc_file') {
          this.doc_file         =   file; 
          this.form['doc_file_name']    =   file.name; 
        }  
    }
    token_alert(){
      if(typeof this.token === 'undefined' || this.token == ''){  
        alert('This URL is invalid please contact caprock support team');
        location.href='https://caprock.com.au'
      }
    }

    get_doc_details(doc_token:any) { 
        Common.get_doc_details(doc_token)
          .then(response => {
              if (typeof response.data !== 'undefined' && response.data.success == 200) {   
                var doc_data=response.data.doc_data;
                this.doc_name=doc_data.doc_name; 
                document.title = this.doc_name+' || Caprock Merchant Platform';  
              }
          }).catch(e => {
            console.log(e)
        });
    }
    get_link_details(link_code:any) { 
        Common.get_link_details(link_code)
          .then(response => {
              if (typeof response.data !== 'undefined' && response.data.success != 200) {    
                alert('Link expired!')
                location.href="https://caprock.com.au"
              }
          }).catch(e => {
            console.log(e)
        });
    }
 
     
    submitForm(e:any){  
      let form_data = new FormData();   
      if(typeof this.doc_file !== 'undefined'){ 
        form_data.append('doc_file', this.doc_file);
        $('#subBtn').attr('disabled','disabled');
        $('#subBtn').text('Processing ... '); 
        form_data.append('token', this.token);  
        form_data.append('doc_name', this.doc_name);   
        form_data.append('link_code', this.link_code);   

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url =Common.base_url()+'upload-document';  
        Axios.post(url, 
          form_data, 
          {
            headers: {  
              'Content-Type': 'multipart/form-data'
            }, 
            onUploadProgress: function( progressEvent:any ) {
              var loaded:number  =  progressEvent.loaded;
              var total:number   =  progressEvent.total;
              var per   =    parseInt((loaded/total *100).toFixed()) ;  
            }.bind(this)
          }
        )
        .then(response => { 
          if(response.data.success==200){    
            (this.$refs['toast'] as any).miralToast( 
                'Sucess',
                response.data.message,
                '', 
                false /*Reload TRUE FALSE*/
            ); 
            setTimeout(() => { 
              this.$router.push('/sign-in'); 
            }, 2500) 

            $('#subBtn').removeAttr('disabled');
            $('#subBtn').text('Upload Now'); 
          }else{ 
            (this.$refs['toast'] as any).miralToast( 
                'Warning',
                response.data.message,
                '',
                false /*Reload TRUE FALSE*/
            ); 
            // location.reload();
            $('#subBtn').removeAttr('disabled');
            $('#subBtn').text('Upload Now');
          } 
        })
        .catch(error => {
          $('#subBtn').removeAttr('disabled');
          $('#subBtn').text('Upload Now'); 
        });
      }else{
        this.doc_file_error='Please select valid document for upload.'; 
        (this.$refs['toast'] as any).miralToast( 
            'Warning',
            this.doc_file_error,
            '',
            false
        );
        return false; 
      } 
             
    } 

    remove_err(event:any){ 
      $('.'+event.currentTarget.id+'err').remove();
    }
 
    openModal(id:any){
      $('#'+id).addClass('d-block');
      $('#'+id).addClass('show');
      $('#'+id).css('overflow-y','scroll');
    }
    closeModal(id:any){
      $('#'+id).removeClass('d-block');
      $('#'+id).addClass('d-none');
      $('#'+id).removeClass('show'); 
    }
 
      
  } 
