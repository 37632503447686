
    import { Options, Vue } from 'vue-class-component';   
    import Common from '@/services/common.js';  
    import App from '../App.vue';  
    import $ from 'jquery';   
    import Basic from '@/components/Basic.vue';    
  
    import { Form, Field,ErrorMessage } from 'vee-validate';
    import { useToast } from "vue-toastification";
    import { useRoute } from 'vue-router'

  import Axios from "axios";
  @Options({
    components: {  
        Basic , 
        Form,
        Field,
        ErrorMessage
    }, 
  })

  export default class AdminCustomerOnboard extends Vue { 
    load_on:any     =   0;
    prev_path :any  =   ''; 
    STORAGE_KEY     =   "caprock-local";   

    applicant_type:any  =  '';
    company_type:any  =  '';  
    toast:any = useToast();
    local_storage:any;
    in_verification : any = 1;
    in_onboarding : any=0;
    

    main_msg    :   any ='';
    token       :   any ='';
    id          :   any ='';
    session     :   any ='';
    url_paras   :   any='';
    is_director     : any=0;
    
    data () {     
        return {  
            form: {
                user_email        : '',
                user_password     : ''
            },
            load_on:this.load_on, 
            applicant_type  :   this.applicant_type,
            company_type    :   this.company_type,

            token           :   this.token,
            is_director     :   this.is_director, 
            id              :   this.id,
            session         :   this.session, 
            url_paras       :   this.url_paras, 
            main_msg        :   this.main_msg,
        }
    }   
    

    beforeCreate(){
        this.load_on=1;
    }

    created() {     
        setTimeout(() => this.load_on=0, 500); 

        this.main_msg = "Please wait while we are generating your registraion application ... ";

        localStorage.clear();  



        var js  = this.local_storage =   JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));     
        const route = useRoute() 
        this.url_paras  = route.query; 
        this.token      = this.url_paras['token']; 
        this.id         = this.url_paras['id']; 
        this.session    = this.url_paras['session']; 
        
        document.title = 'Customer Access || Caprock Merchant Platform';   


        this.get_customer_admin_login(); 
        return true;
    } 

    

    get_customer_admin_login(){ 
        this.load_on=1; 
        let form_data       = new FormData();  
        form_data.append('admin_token'  ,   this.token); 
        form_data.append('id'           ,   this.id);  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url =Common.base_url()+'get-customer-access';
        Axios.post(
            url, 
            form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    }   
                }
        )
        .then(response => { 
            if(response.data.success==200){ 
                 
                localStorage.setItem(
                    this.STORAGE_KEY, JSON.stringify({
                        'access-token':response.data.user_token,
                        'user_info'   :response.data.user_info,
                    })
                );  
                this.local_storage['access-token']=response.data.user_token;
                localStorage.setItem(
                    'system_access' , response.data.access
                );  
                localStorage.setItem('profile_url',  response.data.user_info.profile_url); 
                localStorage.setItem('merchant_logo',  response.data.merchant_logo); 
                localStorage.setItem('merchant_version',  response.data.merchant_version);  

                if(typeof response.data.user_info.user_is_director!='undefined'){
                    this.is_director = response.data.user_info.user_is_director;
                    if(this.is_director==1){
                        this.applicant_type=1;
                    }
                } 

                if(typeof response.data.user_info.company_type!='undefined' && response.data.user_info.company_type!='' && response.data.user_info.company_type!=null){    
                    this.company_type =response.data.user_info.company_type;
                } 
                

                setTimeout(() => this.load_on=0, 500);  
                this.toast.success(response.data.message); 
                var u_type = response.data.user_info.user_type;   

                if(u_type==200){
                    // alert('Please select your application type first');
                    this.in_verification=0;
                }

                this.process_step(u_type,this.company_type); 

            }else{ 
                setTimeout(() => this.load_on=0, 500);  
                this.toast.error(response.data.message);
                this.main_msg = response.data.message;
            } 
        })
        .catch(error => { 
            setTimeout(() => this.load_on=0, 500);  
            this.toast.error('Internal Error');
        });  
    }

     
    process_step(applicant_type,company_type=null){ 
        if(applicant_type==1 || applicant_type=='1'){
            this.$router.push('/individual-app');  
        }
        if(applicant_type==2 || applicant_type=='2'){
            if(company_type!=null){
                if(company_type==1){
                    this.$router.push('/australian-domestic-app');  
                }
                if(company_type==2){
                    this.$router.push('/asic-non-australian-app');  
                }
                if(company_type==3){
                    this.$router.push('/non-australian-app');  
                }
            }
        }  
        if(applicant_type==3 || applicant_type=='3'){
            this.$router.push('/trust-app');  
        } 
        if(applicant_type==4 || applicant_type=='4'){
            this.$router.push('/agent-app');  
        } 
        if(applicant_type==5 || applicant_type=='5'){
            this.$router.push('/association-app');  
        } 
        if(applicant_type==6 || applicant_type=='6'){
            this.$router.push('/govt-bodies-app');  
        } 
        if(applicant_type==7 || applicant_type=='7'){
            this.$router.push('/partnership-app');  
        } 
        if(applicant_type==8 || applicant_type=='8'){
            this.$router.push('/registered-co-operative-app');  
        } 
        if(applicant_type==9 || applicant_type=='9'){
            this.$router.push('/politically-exposed-app');  
        } 
    } 
  } 
