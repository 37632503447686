import Vue from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import UploadDoc from '../views/UploadDoc.vue'
import UploadCustomerDoc from '../views/customer/UploadCustomerDoc.vue'
import UploadInvestorDoc from '../views/UploadInvestorDoc.vue'
import UploadTransactionReceipt from '../views/UploadTransactionReceipt.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Useraccounts from '../views/Useraccounts.vue'
import Bankaccounts from '../views/Bankaccounts.vue'
import Beneficiary from '../views/transaction-request/Beneficiary.vue'
import ManagingMembers from '../views/customer/ManagingMembers.vue'
import BeneficialOwners from '../views/customer/BeneficialOwners.vue'


 


import Documents from '../views/Documents.vue'
import Currency from '../views/Currency.vue'
import Registration from '../views/Registration.vue'
import CorporateRegistration from '../views/CorporateRegistration.vue'
import CorporateApplication from '../views/CorporateApplication.vue'
import IndividualRegistration from '../views/IndividualRegistration.vue'
import IndividualApplication from '../views/IndividualApplication.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

import VerifySecurity from '../views/VerifySecurity.vue'

import AccountDeposit from '../views/AccountDeposit.vue'
import AccountDisbursement from '../views/AccountDisbursement.vue'
import Schedule3Payment from '../views/Schedule3Payment.vue'
import InternalAccountTransfer from '../views/InternalAccountTransfer.vue'
import CommoditiesTradeDesk from '../views/CommoditiesTradeDesk.vue'

import Paymaster from '../views/Paymaster.vue'
import Crypto from '../views/Crypto.vue'
import CashacFees from '../views/CashacFees.vue'

import Fum from '../views/Fum.vue'
import FundacFees from '../views/FundacFees.vue'
/*import Securities from '../views/Securities.vue'*/
import Securities from '../views/securities/Securities.vue'
/*import MyInvestment from '../views/MyInvestment.vue'*/
import MyInvestment from '../views/myinvestments/MyInvestment.vue'
import Properties from '../views/Properties.vue'
import Commodities from '../views/Commodities.vue'
import Custodian from '../views/Custodian.vue'


import InvAdvMenu from '../views/InvAdvMenu.vue'
import CashAccountAgreement from '../views/customer/CashAccountAgreement.vue' 
import UploadGeneralDoc from '../views/general/UploadGeneralDoc.vue'
import EmailESign from '../views/customer/EmailESign.vue'

/*CMS*/
import PlatformAgreement from '../views/cms/PlatformAgreement.vue'
import ImAgreement from '../views/cms/ImAgreement.vue'


/* Onboarding*/ 
  import Signup  from '../views/onboard/Signup.vue'
  import Signin  from '../views/onboard/Signin.vue'
  import Onboard from '../views/onboard/Onboard.vue'
  import ShareholderOnboard from '../views/shareholder/ShareholderOnboard.vue'
  import AdminCustomerOnboard from '../views/onboard/AdminCustomerOnboard.vue'
  import OnboardSuccess from '../views/onboard/OnboardSuccess.vue' 
  

  import IndividualOnboarding  from '../views/onboard/IndividualOnboarding.vue'
  import CorporateOnboarding  from '../views/onboard/CorporateOnboarding.vue'
  // import TrustOnboarding  from '../views/onboard/TrustOnboarding.vue'
  import TrustOnboardingKyc  from '../views/onboard/TrustOnboardingKyc.vue'
  
  import AgentOnboarding  from '../views/onboard/AgentOnboarding.vue'
  import AssociationOnboarding  from '../views/onboard/AssociationOnboarding.vue'
  import RegisteredCoOperativeOnboarding  from '../views/onboard/RegisteredCoOperativeOnboarding.vue'
  import PoliticallyExposedOnboarding  from '../views/onboard/PoliticallyExposedOnboarding.vue'
  import PartnershipOnboarding  from '../views/onboard/PartnershipOnboarding.vue'
  import PartnershipOnboardingKYC  from '../views/onboard/PartnershipOnboardingKYC.vue'
  import GovtBodiesOnboarding  from '../views/onboard/GovtBodiesOnboarding.vue'

  // Australian Domestic Company
  import DomesticCorporate  from '../views/onboard/DomesticCorporate.vue'
  import ASICNonAustralianCorporate  from '../views/onboard/ASICNonAustralianCorporate.vue'
  import NonAustralianCorporate  from '../views/onboard/NonAustralianCorporate.vue'

  import ShareholderDomesticCorporate  from '../views/shareholder/ShareholderDomesticCorporate.vue'
  import ShareholderASICNonAusCorporate  from '../views/shareholder/ShareholderASICNonAusCorporate.vue'
  import ShareholderNonAusCorporate  from '../views/shareholder/ShareholderNonAusCorporate.vue'
  import ShareholderIndividual  from '../views/shareholder/ShareholderIndividual.vue'


  import AssociationOnboardingKYC  from '../views/onboard/AssociationOnboardingKYC.vue'




  // import CorporateOnboarding  from '../views/onboard/CorporateOnboarding.vue' 
  // import CorporateRegistration1  from '../views/onboard/CorporateRegistration1.vue'

/*Investment*/ 
  import InvestmentMultiAsset  from '../views/investment/InvestmentMultiAsset.vue'


/*Transaction Request*/ 
  import Credit  from '../views/transaction-request/Credit.vue'
  import Debit  from '../views/transaction-request/Debit.vue'
  import About  from '../views/About.vue'
  // import CreditRequest  from '../views/transaction-request/CreditRequest.vue'
  // import DebitRequest   from '../views/transaction-request/DebitRequest.vue'

const routes: Array<RouteRecordRaw> = [ 
  
  /*BASIC AUTH*/
  { 
    path      :   '/sign-up', 
    name      :   'Sign Up',
    component :   Signup, 
  }, 
  {
    path: '/registration',
    name: 'Registration',
    component: Signup
  },
  { 
    path      :   '/sign-in', 
    name      :   'Sign In',
    component :   Signin, 
  }, 
  {
    path: '/sign-out',
    name: 'Logout',
    component: Logout
  }, 
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword
  }, 
  {
    path: '/reset-password/:key/:token',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/verify-security-code',
    name: 'VeritySecurity',
    component: VerifySecurity
  }, 

  /*ONBOARDING*/
  { 
    path      :   '/onboard', 
    name      :   'Onboard',
    component :   Onboard, 
  }, 
  { 
    path      :   '/shareholder-profile', 
    name      :   'ShareholderOnboard',
    component :   ShareholderOnboard, 
  },  
  { 
    path      :   '/admin-customer-login', 
    name      :   'AdminCustomerOnboard',
    component :   AdminCustomerOnboard, 
  },  
  { 
    path      :   '/registration-complete', 
    name      :   'OnboardSuccess',
    component :   OnboardSuccess, 
  }, 
  { 
    path      :   '/individual-app', 
    name      :   'Individual App',
    component :   IndividualOnboarding,   
  },
  { 
    path      :   '/corporate-app', 
    name      :   'Corporate App',
    component :   CorporateOnboarding, 
  },
  { 
    path      :   '/australian-domestic-app', 
    name      :   'Australian Domestic Company App',
    component :   DomesticCorporate, 
  },
  { 
    path      :   '/non-australian-app', 
    name      :   'Non Australian Company App',
    component :   NonAustralianCorporate, 
  },
  { 
    path      :   '/asic-non-australian-app', 
    name      :   'ASIC Non Australian Company App',
    component :   ASICNonAustralianCorporate, 
  },{
    path      :   '/shareholder-australian-domestic-app', 
    name      :   'Shareholder Australian Domestic Company App',
    component :   ShareholderDomesticCorporate, 
  },{
    path      :   '/shareholder-non-aus-asic-app', 
    name      :   'Shareholder Non Australian ASIC Company App',
    component :   ShareholderASICNonAusCorporate, 
  },{
    path      :   '/shareholder-non-aus-app', 
    name      :   'Shareholder Non Australian Company App',
    component :   ShareholderNonAusCorporate, 
  },{
    path      :   '/shareholder-individual-app', 
    name      :   'Shareholder Individual App',
    component :   ShareholderIndividual, 
  }, 
  { 
    path      :   '/trust-app', 
    name      :   'Trust App',
    component :   TrustOnboardingKyc, 
  }, 
  { 
    path      :   '/agent-app', 
    name      :   'Agent App',
    component :   AgentOnboarding, 
  }, 
  { 
    path      :   '/association-app', 
    name      :   'Association App',
    component :   AssociationOnboardingKYC,  
  }, 
  { 
    path      :   '/registered-co-operative-app', 
    name      :   'Registered Co Operative App',
    component :   RegisteredCoOperativeOnboarding, 
  }, 
  { 
    path      :   '/politically-exposed-app', 
    name      :   'Politically Exposed Application',
    component :   PoliticallyExposedOnboarding, 
  },  
  { 
    path      :   '/partnership-app', 
    name      :   'Partnership Application',
    component :   PartnershipOnboardingKYC, 
  },   
  { 
    path      :   '/govt-bodies-app', 
    name      :   'Government Bodies Application',
    component :   GovtBodiesOnboarding, 
  },   
  

  /*DASHBOARD & MERCHANT PORTAL*/
  { 
    path      :   '/:pathMatch(.*)*', 
    name      :   'Not Found',
    component :   Home, 
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/investment-advisor-menu/:id',
    name: 'InvAdvMenu',
    component: InvAdvMenu
  },{
    path: '/investment-multi-asset/:id',
    name: 'InvestmentMultiAsset',
    component: InvestmentMultiAsset
  },   
  {
    path: '/currency',
    name: 'Currency',
    component: Currency
  },
  {
    path: '/my-profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/my-business-profile',
    name: 'Business Profile',
    component: Profile
  },
  {
    path: '/my-accounts',
    name: 'Useraccounts',
    component: Useraccounts
  },
  {
    path: '/bank-accounts',
    name: 'Bankaccounts',
    component: Bankaccounts
  },
  {
    path: '/my-documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/my-beneficiary',
    name: 'Beneficiary',
    component: Beneficiary
  },
  {
    path: '/managing-members',
    name: 'Managing Members',
    component: ManagingMembers
  },
  {
    path: '/beneficial-owners',
    name: 'Beneficial Owners',
    component: BeneficialOwners
  },
  {
    path: '/my-account/paymaster',
    name: 'Paymaster',
    component: Paymaster
  },
  {
    path: '/my-account/crypto',
    name: 'Crypto',
    component: Crypto
  },
  {
    path: '/cashac-fees',
    name: 'CashacFees',
    component: CashacFees
  },
  {
    path: '/fundac-fees',
    name: 'FundacFees',
    component: FundacFees
  },
  {
    path: '/my-account/fum',
    name: 'Fund Management',
    component: Fum
  },
  {
    path: '/my-account/securities',
    name: 'Securities',
    component: Securities
  },
  {
    path: '/my-account/my-investment',
    name: 'My Investments',
    component: MyInvestment
  },
  {
    path: '/my-account/property',
    name: 'Properties',
    component: Properties
  },
  {
    path: '/my-account/commodities',
    name: 'Commodities',
    component: Commodities
  },
  {
    path: '/my-account/custodian',
    name: 'Custodian',
    component: Custodian
  }, 
  {
    path: '/credit-request',
    name: 'Credit Transaction Request',
    component:Credit,
  }
  ,{
    path: '/debit-request',
    name: 'Debit Transaction Request',
    component:Debit,
  },



  /*DIRECT LINK ACCESS USING TOKEN*/
  { 
    path      :   '/email-e-sign', 
    name      :   'Email E-Signature',
    component :   EmailESign, 
  },   
  {
    path: '/upload-document',
    name: 'Upload Merchant Doc',
    component: UploadCustomerDoc
  }, 
  {
    path: '/upload-general-doc',
    name: 'Upload General Doc',
    component: UploadGeneralDoc
  }, 
  {
    path: '/upload-account-doc',
    name: 'Upload Doc',
    component: UploadDoc
  }, 
  {
    path: '/upload-investor-document',
    name: 'Upload Investor Document',
    component: UploadInvestorDoc
  },  
  {
    path: '/cash-account-agreement',
    name: 'Cash Account Agreement',
    component: CashAccountAgreement
  },  
  {
    path: '/upload-transaction-receipt',
    name: 'Upload Transaction Receipt',
    component:UploadTransactionReceipt,
  },  


  /*CMS PAGES*/
  {
    path: '/terms-of-use',
    name: 'Terms',
    component: Terms
  },  
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy
  }, 
  {
    path: '/platform-agreement',
    name: 'Platform Agreement',
    component:PlatformAgreement,
  }, 
  {
    path: '/im-agreement',
    name: 'IM Agreement',
    component:ImAgreement,
  } ,
  {
    path: '/(*)',
    redirect: '/',
  },
  { 
    path      :   '/maintenance', 
    name      :   'About',
    component :   About, 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
document.title = 'Caprock Merchant Platform';
 

export default router
