
    import { Options, Vue } from 'vue-class-component';
    import HelloWorld from '@/components/HelloWorld.vue';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 
    import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: {
            HelloWorld, 
            Basic,
            'apexchart': VueApexCharts
        }
    })

    export default class Home extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        customer_accounts   =   []; 
        loading             =   1;  
        total_ac_balance    =   0;  
        local_storage:any;
        dashboard = {};
        portfolio_balance :any= 0;
        alerts:any = [];

        year_chart_values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
         
        chartOptions= {
            chart: {
                id          :   "vuechart-example",
                type        :   "bar",
                height      :   180,
                foreColor   :   '#85addb',
                toolbar     :   {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#FF6633'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series = [
            {
                name: "Balance",
                data: this.year_chart_values,
            },
        ];
 

        created() {     
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                /*console.log('Try After Sometime');*/
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                // location.href='/sign-in'; 
            } 
            document.title = "Home || Caprock Merchant Platform";
            localStorage.setItem(
                'active_page', 'dashboard'
            );  
        }
        async mounted(){
            this.user_name=''; 
            await this.get_user_accounts(); 
            await this.get_dashboard();  
            
        }

        get_user_accounts() { 
            Common.get_user_accounts(this.local_storage['access-token'],1)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        var c_acs =response.data.accounts;
                            
                        this.total_ac_balance= response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;   

                        for (let i = 0; i < c_acs.length; i++) {   
                            var ser= [{
                                name:   'Balance', 
                                data:   c_acs[i]['ac_chart']
                            }];
                            var chartO= {
                                chart: {
                                    height: 200,
                                    type: 'area',
                                    toolbar: {
                                        show: false
                                    }
                                }, 
                                labels: {
                                    show: false,
                                },
                                grid: {
                                    show: false,
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 2
                                },
                                xaxis: {  
                                    categories: moment.monthsShort(),
                                    labels: {
                                        show: false 
                                    } ,
                                    lines: {
                                        show: false
                                    } 
                                },
                                yaxis: { 
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false,
                                    }, 
                                    labels: {
                                        show: false 
                                    }  
                                },
                                colors: [c_acs[i]['ac_color']],
                                dataLabels: {
                                    enabled: false
                                }  
                            }; 

                            c_acs[i]['series']          =ser;
                            c_acs[i]['chartOptions']    =chartO;
                        } 
                        this.customer_accounts=c_acs;
                        this.loading=0; 


                        var  all_acs    =   this.customer_accounts;
                        var ac_count    =   this.customer_accounts.length;
                        var credit      =0;
                        var debit       =0;
                        var balance     =0;
                        var d_balance   =0;
                        for (let i = 0; i < all_acs.length; i++) { 
                            credit      =credit+all_acs[i]['userac_credit'];
                            debit       =debit+all_acs[i]['userac_debit'];
                            balance     =balance+all_acs[i]['userac_balance'];
                            d_balance   =d_balance+all_acs[i]['default_currency_balance']; 
                            // this.d_currency      =  all_acs[i]['default_currency'];
                            // this.d_currency_icon =  all_acs[i]['default_currency_icon'];
                        } 
                        this.portfolio_balance=d_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;

                    }
                    this.loading=0; 
                }).catch(e => {
                  console.log(e)
            });
        }
        get_dashboard() { 
            Common.get_dashboard(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.dashboard          =   response.data.dashboard; 
                        this.year_chart_values  =   this.dashboard['year_chart'];
                        
                        var alerts              =   this.dashboard['alerts'];
                        
                        for (let i = 0; i < alerts.length; i++) {  

                            if(alerts[i]['inv_investment_type_tag']==1){
                                alerts[i]['alert_URL'] =    '/investment-advisor-menu/'; 
                                if(alerts[i]['inv_type']!='Private Label'){
                                    alerts[i]['inv_type']  =  alerts[i]['inv_type']+' (MIS)';              
                                } 
                                alerts[i]['inv_type']  =  'MIS';              
                                
                            }else if (alerts[i]['inv_investment_type_tag']==2){
                                alerts[i]['alert_URL'] =    '/investment-multi-asset/'; 
                                alerts[i]['inv_amount'] =   alerts[i]['ms_min_investment']; 
                                alerts[i]['inv_type']   =   'Model Portfolio';                                  

                            }else{
                                alerts[i]['alert_URL'] = '/investment-multi-asset/';
                            }
                        }



                        this.alerts             =   alerts; 
                        var new_options         = this.chartOptions; 
                        var new_series = [
                            {
                                name: "Balance",
                                data: this.year_chart_values,
                            },
                        ]; 
                        this.series=new_series
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        /*view_investment_advisor_menu(id) { 
            alert(id)
        }*/
 

    }
