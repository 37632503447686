
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import $ from 'jquery';   
  import Basic from '@/components/Basic.vue';  
  import { Form, Field,ErrorMessage } from 'vee-validate';
  import { useToast } from "vue-toastification";


  import Axios from "axios";
  @Options({
    components: {  
      Basic,
      Form,
      Field,
      ErrorMessage
    },
     
  })

  export default class ResetPassword extends Vue {
    errors : string[]=[];
    load_on:any     =   0;
    err : any[]=[];
    form      ={};
    countries =[];
    states    =[]; 
    cities    = []; 
    key :any = ''; 
    token :any = '';
    toast:any = useToast();
    STORAGE_KEY = "caprock-local";  
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");


    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';   
      return {  
        
        load_on:this.load_on, 
        key:this.key, 
        token:this.token, 
        form: {
          new_password        : '',
          confirm_password    : ''
        }  
      }
    }  


    beforeCreate(){
      this.load_on=1;
    }   
 
    async created() {   
      this.key = this.$route.params.key;
      this.token = this.$route.params.token;

      setTimeout(() => this.load_on=0, 500); 
      document.title = "Reset Password || Caprock Merchant Platform"; 
      await this.validate_token();
      return true;
    }
  

    async validate_token(){
        this.load_on=1;  
        let form_data       = new FormData();  
        form_data.append('key'              ,   this.key); 
        form_data.append('token'            ,  this.token); 
        
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url =Common.base_url()+'validate-reset-password';
        await Axios.post(
          url, 
          form_data, 
          {
              headers: {  
                'Content-Type': 'multipart/form-data'
              } 
          }
        )
        .then(response => { 
          if(response.data.success==200){ 
              var status = response.data.success;    
              if (status !=200) { 
                this.$router.push('/sign-in'); 
              } 
              setTimeout(() => this.load_on=0, 500);   
          }else{ 
            setTimeout(() => this.load_on=0, 500);  
            this.toast.error(response.data.message);
            } 
        })
        .catch(error => { 
            setTimeout(() => this.load_on=0, 500);  
            this.toast.error('Internal Error');
        });  
           
    }

    requiredField(value) { 
      if (!value) {
          return 'This field is required';
      } 
      return true;
    }

    submitPassword(){
        this.load_on=1;  
        let form_data       = new FormData();  
        form_data.append('key'              ,   this.key); 
        form_data.append('token'            ,  this.token);
        form_data.append('new_password'     ,  this.form['new_password']);
        form_data.append('confirm_password' ,  this.form['confirm_password']); 
       
        var message = '';
        var error   = 0; 
        if(error!=0){
            setTimeout(() => this.load_on=0, 500);  
            this.toast.error(message);
        }else{  
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url =Common.base_url()+'do-reset-password';
            Axios.post(
              url, 
              form_data, 
              {
                headers: {  
                  'Content-Type': 'multipart/form-data'
                } 
              }
            )
            .then(response => { 
                if(response.data.success==200){ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);
                    setTimeout(() => this.$router.push('/sign-in'), 200);   
                }else{ 
                  setTimeout(() => this.load_on=0, 500);  
                  this.toast.error(response.data.message);
                } 
            })
            .catch(error => { 
                setTimeout(() => this.load_on=0, 500);  
                this.toast.error('Internal Error');
            });  
        }  
    }
     
      
  } 
