import Axios from 'axios';
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

var base_url    =   ''; 
var api_url     =   '';
// base_url        =   'http://127.0.0.1:8000/'; 
// api_url         =   'http://127.0.0.1:8001/'; 
 
// base_url    =   'https://devapi.caprock.com.au/';
// api_url     =   'https://devadmin.caprock.com.au/';  
 
base_url    = 'https://api.caprock.com.au/';
api_url     = 'https://admin.caprock.com.au/';
 

class Common {
    // CHAT MODULE
    get_caprock_messages(token,page_no) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        form_data.append('page_no',page_no);  
        var url = base_url+'get-caprock-messages';  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    start_chat(token){
        let form_data = new FormData();
        form_data.append('user_token',token);   
        var url = base_url+'start-chat';  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_chat_info(token){
        let form_data = new FormData();
        form_data.append('user_token',token);   
        var url = base_url+'get-chat-info';  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }



    base_url(){
        return base_url;
    }
    api_url(){
        return api_url;
    }
    get_all_countries(data) {
        return Axios.post('/app-country-list', data);
    }

    get_country_states(data) {
        return Axios.post('/state-list', data);
    }
    get_state_list(country_id) {
        let form_data = new FormData();
        form_data.append('country_id',country_id);  
        var url = base_url+'state-list';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options); 
    }
    get_terms() {
        var url = base_url+'get-terms'; 
        return Axios.get(url);
    }
    get_privacy() {
        var url = base_url+'get-privacy'; 
        return Axios.get(url);
    }
    get_platform_agreement() {
        var url = base_url+'get-platform-agreement'; 
        return Axios.get(url);
    }
    get_im_agreement() {
        var url = base_url+'get-im-agreement'; 
        return Axios.get(url);
    }

    get_state_cities(data) {
        return Axios.post('/app-city-list', data);
    }

    get_device_info() {
        var url = base_url+'device-info'; 
        return Axios.get(url);
    }
    generate_unique_id() {
        var unique_id = 12345; 
        return unique_id;
    }

    get_place_search(text) { 
        var bodyFormData = new FormData();
        bodyFormData.append('place', text);   
        var url = base_url+'get-place-list'; 
        return Axios.post(url,bodyFormData);
    }
    
    get_onboard_stage(token) {   
        var url = base_url+'get-onboard-stage/'+token;    
        return Axios.get(url); 
    }
    switch_b_account(token,a_profile,cNo) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('a_profile',a_profile);  
        form_data.append('company_no',cNo);  
        var url = base_url+'switch-business-profile';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_trustees(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'trustees';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_beneficiaries(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'trust-beneficiaries';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_trustee_recipients(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'trustee-recipients';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_trustee_directors(token,trustee_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        form_data.append('trustee_id',trustee_id);  
        var url = base_url+'trustee-managing-directors';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_directors(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'managing-directors';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_partners(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'managing-partners';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_shareholder_directors(token,amad) { 
        let form_data = new FormData();
        form_data.append('token',token);  
        form_data.append('amad',amad);  
        var url = base_url+'shareholder-managing-directors';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_directors_token(token) { 
        let form_data = new FormData();
        form_data.append('token',token);  
        var url = base_url+'managing-directors-token';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    get_new_director_info(email_id) { 
        let form_data = new FormData();
        form_data.append('email_id',email_id);  
        var url = base_url+'get-director-info';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_partners_token(token) { 
        let form_data = new FormData();
        form_data.append('token',token);  
        var url = base_url+'managing-partners-token';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_new_partner_info(email_id) { 
        let form_data = new FormData();
        form_data.append('email_id',email_id);  
        var url = base_url+'get-partner-info';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }


    get_shareholder_shareholders(token,amad) { 
        let form_data = new FormData();
        form_data.append('token',token);  
        form_data.append('amad',amad);    
        var url = base_url+'shareholder-shareholders';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_shareholders(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'shareholders';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_shareholders_token(token) { 
        let form_data = new FormData();
        form_data.append('token',token);  
        var url = base_url+'shareholders-token';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_new_shareholder_info(email_id) { 
        let form_data = new FormData();
        form_data.append('email_id',email_id);  
        var url = base_url+'get-shareholder-info';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_bank_accounts(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'bank-accounts';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_shareholder_bank_accounts(token,amad) { 
        let form_data = new FormData();
        form_data.append('token',token);
        form_data.append('amad',amad);  
        var url = base_url+'shareholder-bank-accounts';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    app_bank_accounts(token){
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'app-bank-accounts';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_customer_admin_login(token,id){
        let form_data = new FormData(); 
        form_data.append('admin_token',token);  
        form_data.append('id',id);     
        var url = base_url+'get-admin-access';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_owners(token,search='') { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        form_data.append('search',search); 
        var url = base_url+'beneficial-owners';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_active_onboarding_reg_step(user_token,req_type){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        form_data.append('req_type',req_type);   
        var url = base_url+'active-onboarding-detail';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 


    get_active_shareholder_onboarding_reg_step(token,amad){
        let form_data = new FormData();
        form_data.append('token',token);   
        form_data.append('amad',amad);   
        var url = base_url+'active-shareholder-onboarding-detail';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 

 
    get_user_accounts(token,chart_on=0) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('chart_on',chart_on); 
        var url = base_url+'customer-accounts';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_app_user_accounts(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        var url = base_url+'app-user-accounts';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_general_docs(token,ref_id,T_type,search) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('ref_id',ref_id); 
        form_data.append('T_type',T_type); 
        form_data.append('search',search); 
        var url = base_url+'general-docs';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_countries_list() { 
        let form_data = new FormData(); 
        var url = base_url+'countries-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_doc_details(token) { 
        let form_data = new FormData();
        form_data.append('doc_token',token); 
        var url = base_url+'doc-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_general_doc_details(token) { 
        let form_data = new FormData();
        form_data.append('doc_token',token); 
        var url = base_url+'general-doc-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_link_details(token) { 
        let form_data = new FormData();
        form_data.append('link_code',token); 
        var url = base_url+'link-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_user_info(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        var url = base_url+'profile';  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_shareholder_info(token,amad) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('amad',amad); 
        var url = base_url+'shareholder-profile';  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_documents(token,search) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('search',search); 
        var url = base_url+'customer-docs';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_beneficiary(token,search){
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('search',search); 
        var url = base_url+'get-beneficiary-list';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_members(token,search){
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('search',search); 
        var url = base_url+'managing-directors';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
     
    get_member_info(token,id){
        let form_data = new FormData();
        form_data.append('user_token',token);  
        form_data.append('director_id',id);   
        var url = base_url+'get-managing-member-info';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_securities_documents(token,fum_id,doc_type='securities',search) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('search',search); 
        form_data.append('fum_id',fum_id); 
        form_data.append('doc_type',doc_type); 
        var url = base_url+'get-securities-docs';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }


    get_inv_adv_menu(token,inv_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('inv_id',inv_id); 
        var url = base_url+'investment-advisor-menu-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    participate_inv_adv_menu(token,inv_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('inv_id',inv_id); 
        var url = base_url+'participate-investment-advisor-menu';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    get_account_detail(token,ac_name) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('ac_name',ac_name); 
        var url = base_url+'customer-account';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    get_dashboard(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        var url = base_url+'dashboard';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    change_password(token,new_pass,confirm_pass) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('new_password',new_pass); 
        form_data.append('confirm_password',confirm_pass); 
        
        var url = base_url+'change-password';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    is_approval_valid(approval_id,token) { 
        let form_data = new FormData();
        form_data.append('approval_id',approval_id);   
        form_data.append('token',token);   
        var url = base_url+'approval-data';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }


    get_fund_managers(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'get-fund-managers';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_basic_info(user_token,fum_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('fum_id',fum_id);   
        var url = base_url+'get-securities-basic';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_securities_acqusition_basic(user_token,fum_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('fum_id',fum_id);   
        var url = base_url+'get-securities-acquisition-basic';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_securities_fum(user_token,fum_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('fum_id',fum_id);   
        var url = base_url+'get-securities-fum-data';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_securities_investors(user_token,fum_id,security_id=''){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('fum_id',fum_id);   
        form_data.append('security_id',security_id);   
        var url = base_url+'get-securities-investor-data';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_cashac_fees(user_token,subac_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('subac_id',subac_id);     
        var url = base_url+'cashac-fees';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_crypto_transactions(user_token,subac_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('subac_id',subac_id);     
        var url = base_url+'crypto-transactions';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
     get_transactions(user_token,subac_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('subac_id',subac_id);     
        var url = base_url+'cashac-transactions';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_fundac_fees(user_token,subac_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('subac_id',subac_id);     
        var url = base_url+'fundac-fees';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_securities_fees(user_token,fum_id,security_id=''){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('fum_id',fum_id);     
        var url = base_url+'get-securities-fees';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_fum_securities(user_token,fum_id,security_id=''){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('fum_id',fum_id);     
        form_data.append('security_id',security_id);     
        var url = base_url+'get-fum-securities';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }




    /*MYINVESTMENTS*/
    get_myinvestments(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'get-myinvestments';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 
    get_myinv_basic_info(user_token,myinv_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('myinv_id',myinv_id);   
        var url = base_url+'get-myinvestment-basic';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_myinvestment_interest_basic(user_token,myinv_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('myinv_id',myinv_id);   
        var url = base_url+'get-myinvestment-interest-basic';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 
    get_myinvestment_investment(user_token,myinv_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('myinv_id',myinv_id);   
        var url = base_url+'get-myinvestment-investment-data';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 
    get_myinvestment_interest_payments(user_token,myinv_id){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('myinv_id',myinv_id);     
        var url = base_url+'get-myinvestment-interest-payments';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_myinvestment_documents(token,myinv_id,doc_type='docs',search) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('search',search); 
        form_data.append('myinv_id',myinv_id); 
        form_data.append('doc_type',doc_type); 
        var url = base_url+'get-myinvestment-docs';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_customer_cash_ac(user_token,except_from=''){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('except_from',except_from);   
        var url = base_url+'customer-cash-accounts';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_customer_securities(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);     
        var url = base_url+'customer-security-accounts';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    balance_cash_ac(user_token,subac_id,amount){
        let form_data = new FormData();
        form_data.append('user_token',user_token);  
        form_data.append('cashac_id',subac_id);   
        form_data.append('amount',amount);   
        var url = base_url+'balance-check-cash-account';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 
    user_merchant_logo(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'user-merchant-logo';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }  
    user_merchant_version(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'user-merchant-version';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 

    


    /*Transaction Request*/
    get_active_trans_req_step(user_token,req_type){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        form_data.append('req_type',req_type);   
        var url = base_url+'active-trans-request';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    } 

    get_users(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'get-user-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_beneficiary_list(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'get-beneficiary-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_beneficiary_info(user_token,id){
        let form_data = new FormData();
        form_data.append('user_token',user_token); 
        form_data.append('beneficiary_id',id);   
        var url = base_url+'get-beneficiary-info';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    currency_list(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);   
        var url = base_url+'get-currency-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_user_portfolio(user_token){
        let form_data = new FormData();
        form_data.append('user_token',user_token);
        var url = base_url+'customer-portfolio';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

}
export default new Common();