
  import { Options, Vue } from 'vue-class-component';
  import $ from 'jquery';
  import Common from '@/services/common.js';
  import Toast from '@/components/Toast.vue';  
  import Axios from "axios";  
  import 'bootstrap'; 
  import Loader from '@/components/Loader.vue';   
  @Options({
    props: {
      msg: String,
      load_on: Number,  
    },
    components: { 
      'toast':Toast,
      'loader':Loader,
    }  
  })
  export default class Basic extends Vue {
    msg!: string
    STORAGE_KEY =   "caprock-local";   
    session     =   JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}"); 
    token       =   '';
    system_access :any= localStorage.getItem('system_access');
    active_page     =   localStorage.getItem('active_page'); 
    isPopupDisable:any  =   localStorage.getItem('isPopupDisable'); 
    user_name   = ''; 
    user_is_director = 0;
    businesses :any  = [];
    error       = ''; 
    success     = ''; 
    form        =   {
      userac_id :'',
      subac_id  :'',
      ttype      :'all',
      from_date :'',
      to_date   :'',  
    };
    merchant_logo     = 0;
    merchant_logo_url:any = localStorage.getItem('merchant_logo'); 
    u_accounts:any = [];
    us_accounts:any=[];
    profile_url   = localStorage.getItem('profile_url'); 
    b_profile_url = 'https://admin.caprock.com.au/inlancer-admin/images/user.png'; 

    profile_type:any ='';
    popupMessage:any='';
    popupTitle:any='';

    data () {     
      if(this.session){
        var js      = this.session;
        this.token  = js['access-token'];
           
        if(typeof(this.token)==='undefined' || this.token==''){
          this.logout();
        } 
        this.user_name=js['user_info']['user_name'];    
      }else{
        this.token='';  
        this.logout();
      } 
      this.profile_type=localStorage.getItem('active_profile'); 
      return {  
        token:this.token,
        profile_type:this.profile_type,
        user_is_director:this.user_is_director,
        merchant_logo : this.merchant_logo,
        merchant_logo_url:this.merchant_logo_url,
        businesses:this.businesses,
        isPopupDisable:this.isPopupDisable
      }

    }    
    created() {      
      this.get_user_accounts(); 
      this.get_merchant_version();
      this.get_merchant_logo();  
      if(this.system_access==2 || this.system_access=='2'){
        location.href='/onboard';  
      }   

      setTimeout(() =>this.checkPopup(),1000);


      return true; 
    }
    logout(type=0){
      localStorage.removeItem(this.STORAGE_KEY); 
      localStorage.clear();
      this.token='';   
      if(type!=1){
        var prev_path = this.$route.fullPath; 
        localStorage.setItem('prev_path',prev_path);  
      } 
      location.href='/sign-in';  
    } 

    checkPopup(){
      if ('popup' in this.session &&  typeof this.session['popup']!='undefined'){
        if ((this.isPopupDisable==null || this.isPopupDisable=='') && 'popup' in this.session['popup'] && this.session['popup']['popup']!='undefined' && this.session['popup']['popup']=='yes'){
          this.notice_alert(this.session['popup']['title'],this.session['popup']['message'])
        }
      } 
    }

    notice_alert(title,message){
      this.popupTitle =title;
      this.popupMessage =message;
      (this.$refs['toast'] as any).openModal('notice_alert');
    }
    closeNotice(){ 
      localStorage.setItem('isPopupDisable','True'); 
      (this.$refs['toast'] as any).closeModal('notice_alert');
    }


    async switch_profile(p_type:any,company_no=0){ 
      if(p_type=='business'){
          /*SWITCH TO MY BUSINESS AC IN DIRECTOR*/ 
          await Common.switch_b_account(this.token,2,company_no)
          .then(response => { 
              if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                localStorage.setItem('active_profile','business_'+company_no); 
                this.profile_type = 'business_'+company_no;
                location.reload();
              }
          }).catch(e => {
              console.log(e)
          });
      } 
      if(p_type=='my'){
          /*SWITCH TO IDDIVIDUAL*/
          await Common.switch_b_account(this.token,1,company_no)
          .then(response => { 
              if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                localStorage.setItem('active_profile','my'); 
                this.profile_type = 'my';
                location.reload();
              }
          }).catch(e => {
              console.log(e)
          });
      } 
    }

    open_report(id){  
      if (typeof id!=='undefined' && id==1){
        (this.$refs['toast'] as any).openModal('transaction_report'); 
      }
      if (typeof id!=='undefined' && id==2){
        (this.$refs['toast'] as any).openModal('portfolio_report');
      } 
    } 
    get_user_accounts() { 
        /*Common.get_user_accounts(this.token)
            .then(response => { 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var ac_data = response.data.accounts; 
                    for (let i = 0; i < ac_data.length; i++) { 
                      ac_data[i]['ac_percent']=(100*ac_data[i]['ac_balance']/response.data.accounts.at(-1).t_balance);
                    }  
                    this.u_accounts = ac_data; 
                }
            }).catch(e => {
              console.log(e)
        });*/
        Common.get_app_user_accounts(this.token)
            .then(response => { 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var ac_data     = response.data.accounts;  
                    this.u_accounts = ac_data; 
                }
            }).catch(e => {
              console.log(e)
        });
        
    }
    get_merchant_logo() { 
        Common.user_merchant_logo(this.token)
            .then(response => { 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                  var logo_url = response.data.merchant_logo;   

                  this.businesses = response.data.accounts;
                  if(typeof logo_url!=='undefined' && logo_url!='' && logo_url!=null){
                    this.merchant_logo_url = logo_url;
                    localStorage.setItem('merchant_logo',this.merchant_logo_url); 
                  }
                }
                if (typeof response.data !== 'undefined' && response.data.success == 500 && response.data.screenCode == 0) {  
                  this.logout();
                }else{
                  localStorage.setItem('is_login','1');
                }
                if (typeof response.data !== 'undefined' && typeof response.data.is_director !== 'undefined') {  
                  this.user_is_director = response.data.is_director;
                } 
                if (typeof response.data !== 'undefined' && typeof response.data.user_name !== 'undefined') {  
                  this.user_name = response.data.user_name;
                } 
            }).catch(e => {
              console.log(e)
        });
    } 
    get_merchant_version() { 
        Common.user_merchant_version(this.token)
            .then(response => { 


                var cPath = this.$route.path;  
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                  var system_version  = response.data.system_version;    
                  if(typeof system_version!=='undefined' && system_version!='' && system_version!=null){ 
                    var local_system_version =  localStorage.getItem(cPath+'system_version');    
                    // var local_system_version =  localStorage.getItem('system_version');    
                    if(local_system_version!=system_version){ 
                      localStorage.setItem(cPath+'system_version',system_version);  
                      // localStorage.setItem('system_version',system_version);  
                      window.location.reload(true)
                    }  
                    localStorage.setItem(cPath+'system_version',system_version);  
                    // localStorage.setItem('system_version',system_version);  
                  } 

                  
                  var merchant_version  = response.data.merchant_version;   
                  if(typeof merchant_version!=='undefined' && merchant_version!='' && merchant_version!=null){ 
                    var local_merchant_version =  localStorage.getItem('merchant_version');  
                    if(local_merchant_version!=merchant_version){ 
                      this.get_merchant_logo(); 
                      localStorage.setItem('merchant_version',merchant_version); 
                    }  
                  }
                }
            }).catch(e => {
            console.log(e)
        });
    }
    fetch_subaccounts(event:any){
      var userac_id = event.target.value;
      if(userac_id>0){
        this.form.userac_id = userac_id;
      }else{
        this.form.userac_id = '';
      }  
      var ac_name = event.target.selectedOptions[0].dataset.name;  
      // var ac_name='paymaster';
      Common.get_account_detail(this.token,ac_name)
      .then(response => { 
          this.us_accounts=[]; 
          if (typeof response.data !== 'undefined' && response.data.success == 200) {   
            this.us_accounts = response.data.ac_data.subaccounts;    
          } 
      }).catch(e => {
        console.log(e)
      }); 
    }
    set_subac(event:any){
      this.form.subac_id = event.target.value; 
    }

    generate_trans_report(e:any){ 
      if(parseInt(this.form.userac_id)==0 || this.form.userac_id==''){
          (this.$refs['toast'] as any).miralToast( 
              'Warning',
              'Please select valid account for transaction report',
              '',
          );
          return false;
      }
      if(parseInt(this.form.subac_id)==0 || this.form.subac_id==''){
          (this.$refs['toast'] as any).miralToast( 
              'Warning',
              'Please select valid sub account for transaction report',
              '',
          );
          return false;
      }
      if(parseInt(this.form.subac_id)>0 && parseInt(this.form.userac_id)>0){
        $('.subBtn').attr('disabled', 'disabled');
        $('.subBtn').text('Sending Request ... ');
        var js = JSON.parse(JSON.stringify((this.form))); 
 
        let form_data = new FormData();
        form_data.append('device', 'vuew'); 
        for (var key in js) {
          form_data.append(key, js[key]);
        }  
        form_data.append('user_token', this.token);
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url = Common.base_url()+'trans-report-request'; 
        Axios.post(url,
            form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent: any) {
                    var loaded: number = progressEvent.loaded;
                    var total: number = progressEvent.total;
                    var per = parseInt((loaded / total * 100).toFixed());
                }.bind(this)
            }
        )
        .then(response => {
            var title = '';
            if (response.data.success == 200) {
              /*  alert(response.data.message);*/
                title = 'Success'; 
                (this.$refs['toast'] as any).miralToast( 
                    title,
                    response.data.message,
                    '',
                    true
                );
                setTimeout(() =>this.$router.push('/'),3500);
            } else {
               /* alert(response.data.message);*/
                title = 'Failed';
                (this.$refs['toast'] as any).miralToast( 
                    title,
                    response.data.message,
                    '',
                    false
                );
                $('.subBtn').removeAttr('disabled');
                $('.subBtn').text('Generate Now');
            }   
        })
        .catch(error => {
            $('.subBtn').removeAttr('disabled');
            $('.subBtn').text('Generate Now');
        });
        
      }    
    } 
    generate_portfolio_report(e:any){   
      $('.subBtn').attr('disabled', 'disabled');
      $('.subBtn').text('Sending Request ... '); 
      $('.noBtn').addClass('d-none'); 
      let form_data = new FormData();
      form_data.append('device', 'vuew');  
      form_data.append('user_token', this.token);
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      var url = Common.base_url()+'portfolio-report-request'; 
      Axios.post(url,
          form_data, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: function(progressEvent: any) {
                  var loaded: number = progressEvent.loaded;
                  var total: number = progressEvent.total;
                  var per = parseInt((loaded / total * 100).toFixed());
              }.bind(this)
          }
      )
      .then(response => {
          var title = '';
          if (response.data.success == 200) {
            /*  alert(response.data.message);*/
              title = 'Success'; 
              (this.$refs['toast'] as any).miralToast( 
                  title,
                  response.data.message,
                  '',
                  true
              );
              setTimeout(() =>this.$router.push('/'),3500);
          } else {
             /* alert(response.data.message);*/
              title = 'Failed';
              (this.$refs['toast'] as any).miralToast( 
                  title,
                  response.data.message,
                  '',
                  false
              );
              $('.subBtn').removeAttr('disabled');
              $('.subBtn').text('Apply Now');
          }   
      })
      .catch(error => {
          $('.subBtn').removeAttr('disabled');
          $('.portReportBtn').text('Yes');
          $('.noBtn').removeClass('d-none'); 
      });
           
    } 


    mounted(){
      document.body.classList.add('overflow-y-scroll')
    }
    loadImage(){
      this.profile_url = this.b_profile_url;
    }

    

  }
