
    import { Options, Vue } from 'vue-class-component';   
    import Common from '@/services/common.js';  
    import App from '../App.vue';  
    import $ from 'jquery';  
    import 'bootstrap';
    import Basic from '@/components/Basic.vue';  
    import Toast from '@/components/Toast.vue'
    import { useRoute } from 'vue-router'
    import moment from 'moment';
    import Axios from "axios";
 
 
    @Options({
        components: {  
          Basic,
          'toast':Toast
        }, 
    })
    export default class EmailESign extends Vue {  
        STORAGE_KEY         =   "caprock-local";   
        user_name           =   ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        dashboard = {
          currencies:[]
        };
        alerts:any          =   []; 
        form                =   {};

        

        url_paras   :any  ;  
        user_token   :any  ;  
        subject   :any      =  '';  
        doc_name  :any      =  '';
        docs :any           =   []; 
        current_date        =   moment(new Date()).format("DD/MM/YYYY");
        agreement_text      =   "";  
        confirm :any        =   0;  
        confirm_error       =   '';
        error_flag          =   0;
        errors : string[]   =   [];
        err : any[]         =   [];
        

        data () {    
          Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';     
          const route = useRoute() 

          this.url_paras  = route.query;  
          this.subject    = this.url_paras['subject']; 
          this.doc_name   = this.url_paras['doc'];   

          this.docs       =this.doc_name.split(","); 
         
          return {  
            form: { 
              e_name        : '',
              e_phone_no    : '',
              e_email       : '',
              e_dob         : '',
              curdate       : moment(new Date()).format("YYYY-MM-DD"),
              user_token    : ''
            } 

          }

        }   

        created() {     
          var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
          if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('Try After Sometime');*/
          }else{ 
            localStorage.removeItem(this.STORAGE_KEY);  
            var prev_path = this.$route.fullPath; 
            localStorage.setItem('prev_path',prev_path);   
            this.$router.push('/sign-in'); 
          }   
          document.title        =   "Email E-Signature || Caprock Merchant Platform";
          var curr_path         =   this.$route.query; 
          this.subject          =   curr_path.subject; 
          this.doc_name         =   curr_path.doc;   
          this.agreement_text   =   "I accept and agree to be bound by the terms and conditions contained in the following documents: \n"+this.doc_name;  

        }

        mounted(){
          this.user_name='';  
        } 

        confirmEvent(e){
          if(e.target.checked){
            this.confirm=1;
            this.confirm_error='';
          }
        }

        submitForm(e:any){  
          $('#subBtn').attr('disabled', 'disabled');
          $('#subBtn').text('Processing ... ');  
          this.form['user_token']       = this.local_storage['access-token']; 
          this.form['agreement_text']   = this.agreement_text; 
          this.form['subject']          = this.subject;  
          this.form['curdate']          = moment(new Date()).format("YYYY-MM-DD");  
          this.form['log_id']           = this.url_paras['ref'];  
          var js = JSON.parse(JSON.stringify((this.form)));   
          /*Validation Check*/
            this.errors       =   [];
            this.error_flag   =   0;
            var required  = [
              { 'key': 'e_name'     , 'name': 'Your Name' }, 
              { 'key': 'e_email'    , 'name': 'Email' }, 
              { 'key': 'e_phone_no' , 'name': 'Phone No' },
              { 'key': 'e_dob'      , 'name': 'Date Of Birth' } 
            ];
            var valid_emails = [ 
              { 'key': 'e_email', 'name': 'E-Signature Email' }
            ];

            var valid_phones = [ 
              { 'key': 'e_phone_no', 'name': 'E-Signature Phone' }
            ];  
            var new_errors: any = []; 
            for (let i = 0; i < valid_emails.length; i++) {
                this.validEmail(valid_emails[i]['key'], valid_emails[i]);
            }
            for (let i = 0; i < required.length; i++) {
                this.requiredInput(required[i]['key'], required[i]);
            }
          /*Validation Check*/ 
             
          let form_data = new FormData();  
          if(this.confirm==0){
            this.confirm_error  = "Please agree to condition.";
            this.error_flag     = 1;
          }else{
            this.confirm_error="";
          }
 

          if(this.error_flag == 0){  
            for (var key in js) {  
              form_data.append(key, js[key]);  
            }
            $('#subBtn').attr('disabled','disabled');
            $('#subBtn').text('Processing ... ');        

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url =Common.base_url()+'e-signature-submit';  
            Axios.post(url, 
              form_data, 
              {
                headers: {  
                  'Content-Type': 'multipart/form-data'
                }, 
                onUploadProgress: function( progressEvent:any ) {
                  var loaded:number  =  progressEvent.loaded;
                  var total:number   =  progressEvent.total;
                  var per   =    parseInt((loaded/total *100).toFixed()) ;  
                }.bind(this)
              }
            )
            .then(response => { 
              if(response.data.success==200){    
                (this.$refs['toast'] as any).miralToast( 
                    'Sucess',
                    response.data.message,
                    '', 
                    false /*Reload TRUE FALSE*/
                ); 
                setTimeout(() => { 
                  this.$router.push('/sign-in'); 
                }, 2500) 

                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Submit Now'); 
              }else{ 
                (this.$refs['toast'] as any).miralToast( 
                    'Warning',
                    response.data.message,
                    '',
                    false 
                );  
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Submit Now');
              } 
            })
            .catch(error => {
              $('#subBtn').removeAttr('disabled');
              $('#subBtn').text('Submit Now'); 
            });
          }else{
            var errrr ='Please fill complete form before submiting.'; 
            (this.$refs['toast'] as any).miralToast( 
                'Warning',
                errrr,
                '',
                false
            );
            $('#subBtn').removeAttr('disabled');
            $('#subBtn').text('Submit Now');
            return false; 
          } 
                 
        }  

        checkPhone(event: any) {
            var tag_name = event.target.name; 
            var ph = event.target.value
            var valid = 1;
            var error_msg = 'Please enter valid phone number'; 
            if (ph.length < 7 || ph.length > 12) {
                valid = 0;
                error_msg = 'Phone number length is not valid';
            }
            if (isNaN(ph)) {
                valid = 0;
                error_msg = 'Only number character is allowed';
            }
            if (ph == '') {
                valid = 0;
                error_msg = 'Please enter phone number';
            }
     
            $('.' + tag_name + 'err').html('');
            if (valid ==0) {
                $("#" + tag_name).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + error_msg + '</span>');
            }
        } 
        validEmail(e_id, obj: any) {
            var tag_name = $("#" + e_id).attr("name");
            var e_val = $("#" + e_id).val();
            var valid = 1
            var error_msg = 'Please enter valid email address';
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (typeof obj !== "undefined" && obj !== null) {
                error_msg = 'Please enter a valid email address for field - ' + obj.name;
            }
            if (!re.test(String(e_val).toLowerCase())) {
                valid = 0;
            }
            $('.' + tag_name + 'err').html('');
            if (valid == 0) {
                this.error_flag=1;
                $("#" + e_id).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + error_msg + '</span>');
            }
        }

        requiredInput(r_id, obj: any) {
            var tag_name = $("#" + r_id).attr("name");
            var r_val = $("#" + r_id).val();

            var valid = 1;
            var em = 'Field required';

            if (typeof r_val == undefined || r_val == '' || r_val == null) {
                em = tag_name + ' is required';
                valid = 0;
            }
            if (typeof(obj) !== "undefined" && obj != null) {
              em = obj.name+ ' is required';
            }

            if (valid == 0) {
                this.error_flag=1;
                $('.' + tag_name + 'err').html('');
                $("#" + r_id).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + em + '</span>');
            }
        }
        requiredRadio(r_id, obj: any) {
            var tag_name = $('input[name="'+r_id+'"]').attr("name");
            var r_val = this.form[r_id];
             
            var valid = 1;
            var em = 'Field required';

            if (typeof r_val == undefined || r_val == '' || r_val == null) {
                em = 'Field required';
                valid = 0;
            } 
            if (valid == 0) {
                this.error_flag=1;
                $('.' + tag_name + 'err').html('');
                $('#'+r_id+'_label').append('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + em + '</span>');
            }
        }

        remove_err(event:any){ 
          $('.'+event.currentTarget.id+'err').remove();
        }
        removeTagErr(event: any) {
          var tag_name = event.target.name;
          $('.' + tag_name + 'err').html('');
        }


  
        openModal(id:any){
          $('#'+id).addClass('d-block');
          $('#'+id).addClass('show');
          $('#'+id).css('overflow-y','scroll');
        }
        closeModal(id:any){
          $('#'+id).removeClass('d-block');
          $('#'+id).addClass('d-none');
          $('#'+id).removeClass('show'); 
        }

    }

